import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js"
import classNames from "classnames"

import Stimulus from "../../common/utils/stimulus"

class FormInput extends Controller {
  static values = {
    choices: Boolean,
    choicesMax: String,
    choicesMaxText: String,
    choicesNowrap: Boolean,
    choicesSize: String,
  }

  #tagName = this.element.tagName.toLowerCase()
  #choices = null
  #choicesHtml = ``

  connect() {
    if (this.#tagName == `select`) this.initSelect()
  }

  disconnect() {
    if (this.#tagName == `select`) this.deinitSelect()
  }

  // select

  initSelect = () => {
    if (!this.choicesValue) {
      this.toggleSelectPlaceholderClass()
      this.element.addEventListener(`change`, this.toggleSelectPlaceholderClass)
      this.element.addEventListener(`blur`, this.toggleSelectPlaceholderClass)
    }

    if (this.choicesValue && !this.#choices) {
      const options = {
        removeItemButton: true,
        allowHTML: true,
        classNames: {
          containerOuter: classNames({
            choices: true,
            [`is-size-${this.choicesSizeValue}`]: true,
            "is-nowrap": this.choicesNowrapValue,
          }),
        },
      }

      if (this.hasChoicesMaxValue) {
        options.maxItemCount = this.choicesMaxValue

        if (this.hasChoicesMaxTextValue) {
          options.maxItemText = (max) =>
            this.choicesMaxTextValue.replace(`[max]`, max)
        }
      }

      this.#choicesHtml = this.element.outerHTML
      this.#choices = new Choices(this.element, options)
    }

    if (this.choicesValue)
      document.addEventListener(`turbo:before-cache`, this.destroyChoices)
  }

  deinitSelect = () => {
    if (!this.choicesValue) {
      this.element.removeEventListener(
        `change`,
        this.toggleSelectPlaceholderClass
      )

      this.element.removeEventListener(
        `blur`,
        this.toggleSelectPlaceholderClass
      )
    } else {
      document.removeEventListener(`turbo:before-cache`, this.destroyChoices)
    }
  }

  toggleSelectPlaceholderClass = () => {
    this.element.classList.toggle(`--placeholder`, !this.element.value)
  }

  destroyChoices = () => {
    this.#choices.destroy()
    this.element.outerHTML = this.#choicesHtml
  }
}

Stimulus.register(`form-input`, FormInput)
