import { Controller } from "@hotwired/stimulus"

import Stimulus from "../../common/utils/stimulus"

class Flash extends Controller {
  hide() {
    this.element.remove()
  }
}

Stimulus.register(`flash`, Flash)
