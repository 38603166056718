// the Turbo instance is automatically assigned to window.Turbo upon import:

import "@hotwired/turbo-rails"

// deal with turbo navigation and hashes

document.addEventListener(`turbo:render`, (e) => {
  if (typeof window !== `undefined`) {
    if (location.hash) {
      const name = window.location.href.split(`#`)[1]
      const el = document.getElementsByName(name)[0]
      if (el) {
        setTimeout(() => {
          el.scrollIntoView({
            behavior: `smooth`,
            block: `start`,
          })
        }, 200)
      }
    } else {
      window.scrollTo(0, 0)
    }
  }
})

// scroll to the top of the form on submit end

document.addEventListener(`turbo:submit-end`, (e) => {
  e.detail.formSubmission.formElement.scrollIntoView(true)
})

// Turbo wouldn't scroll to the top on page visit on Firefox if
// `scroll-behavior: smooth;` is set on `html`:
// https://github.com/hotwired/turbo/issues/426
// this is the fix:

if (window.getComputedStyle(document.documentElement).scrollBehavior) {
  document.addEventListener(`turbo:load`, () => {
    document.documentElement.style.scrollBehavior = `smooth`
  })

  document.addEventListener(`turbo:before-visit`, () => {
    document.documentElement.style.scrollBehavior = `unset`
  })
}
