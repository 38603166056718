import domReady from "../utils/dom-ready"

const scrollToIt = (el) => {
  el.tabIndex = 0
  el.focus()
  el.scrollIntoView(true)
}

// for turbo forms
// scroll to `.form-failure` element if form submission failed.
// usually Turbo forms scroll viewport to the top of the form automatically,
// we need to patch the cases when the `.form-element` is placed somewhere
// else rather than beginning of the form

document.documentElement.addEventListener(`turbo:submit-end`, (e) =>
  window.setTimeout(() => {
    const formId = e.detail.formSubmission.formElement.id
    if (formId) {
      const formEl = document.getElementById(formId)
      if (formEl) {
        const failureEl = formEl.querySelector(`.form-failure`)
        if (failureEl) {
          scrollToIt(failureEl)
        }
      }
    }
  }, 50)
)

// for usual forms
// scrolls to the very first `.form-failure` is it exists

domReady(() => {
  const failureEl = document.querySelector(`.form-failure`)
  if (failureEl) {
    scrollToIt(failureEl)
  }
})
