import { Controller } from "@hotwired/stimulus"

import application from "../utils/stimulus"

class FormRange extends Controller {
  #trackColor = null
  #trackProgressColor = null

  connect() {
    const styles = window.getComputedStyle(this.element)
    this.#trackColor = styles.getPropertyValue(`--track-color`).trim()
    this.#trackProgressColor = styles
      .getPropertyValue(`--track-progress-color`)
      .trim()

    this.input()
    this.element.addEventListener(`input`, this.input, { passive: true })
  }

  input = () => {
    if (!this.#trackColor || !this.#trackProgressColor) return

    const value = Math.round(
      ((this.element.value - this.element.min) /
        (this.element.max - this.element.min)) *
        100
    )

    this.element.style.setProperty(
      `--webkit-slider-runnable-background`,
      `linear-gradient(to right, ${this.#trackProgressColor} 0%, ${
        this.#trackProgressColor
      } ${value}%, ${this.#trackColor} ${value}%, ${this.#trackColor} 100%)`
    )
  }
}

application.register(`form-range`, FormRange)
